import {storeToRefs} from "pinia";
import {useAuthStore} from "~/stores/auth";
import {
    ROLE_PERMISSION_GRANTS_AVAILABLE,
    ROLE_PERMISSION_TYPES_AVAILABLE
} from "~/types/interfaces/entitites/RoleInterface";

const authStore = useAuthStore();

const {currentUser} = storeToRefs(authStore);

export interface MenuItem {
    heading?: string;
    sectionTitle?: string;
    route?: string;
    pages?: Array<MenuItem>;
    keenthemesIcon?: string;
    bootstrapIcon?: string;
    sub?: Array<MenuItem>;
}

const MainMenuConfig: Array<MenuItem> = [
    {
        pages: [
            {
                heading: 'Dashboard',
                route: '/panel-principal',
                keenthemesIcon: 'element-11',
                bootstrapIcon: 'bi-layout-text-window-reverse'
            }
        ]
    },
];

const UsersMenuConfigPages: Array<MenuItem> = [];

const JudicialSentencesMenuConfigPages: Array<MenuItem> = [];
const JudicialSentencesSubMenuConfigPages: Array<MenuItem> = [];
const JudicialSentencesCourtSubMenuConfigPages: Array<MenuItem> = [];

const PublicContentMenuConfigPages: Array<MenuItem> = [];
const PublicContentFilesSubMenuConfigPages: Array<MenuItem> = [];

const AnalyticsMenuConfigPages: Array<MenuItem> = [];
const AnalyticsSubMenuConfigPages: Array<MenuItem> = [];

const FinancialMenuConfigPages: Array<MenuItem> = [];

const ContentMenuConfigPages: Array<MenuItem> = [];
const ContentSubMenuConfigPages: Array<MenuItem> = [];

if (useUtil().hasTypePermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_USERS"])
    || useUtil().hasTypePermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_PRODUCTS"])
    || useUtil().hasTypePermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_TICKETS"])
    || useUtil().hasTypePermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_CLAIMS"])
    || useUtil().hasTypePermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_ADMINS"])
    || useUtil().hasTypePermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_ROLES"])
) {
    if (useUtil().hasPermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_USERS"], ROLE_PERMISSION_GRANTS_AVAILABLE['GRANT_READ'])) {
        UsersMenuConfigPages.push(
            {
                heading: 'Socios',
                route: '/usuarios/socios/listado',
                keenthemesIcon: 'element-11',
                bootstrapIcon: 'bi-people'
            });
    }
    if (useUtil().hasPermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_PRODUCTS"], ROLE_PERMISSION_GRANTS_AVAILABLE['GRANT_READ'])) {
        UsersMenuConfigPages.push(
            {
                heading: 'Productos',
                route: '/usuarios/productos/listado',
                keenthemesIcon: 'element-11',
                bootstrapIcon: 'bi-file-text'
            });
    }
    if (useUtil().hasPermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_TICKETS"], ROLE_PERMISSION_GRANTS_AVAILABLE['GRANT_READ'])) {
        // UsersMenuConfigPages.push(
        //     {
        //         heading: 'Tickets',
        //         route: '/usuarios/tickets/listado',
        //         keenthemesIcon: 'element-11',
        //         bootstrapIcon: 'bi-chat-square-text'
        //     });
    }
    if (useUtil().hasPermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_CLAIMS"], ROLE_PERMISSION_GRANTS_AVAILABLE['GRANT_READ'])) {
        // UsersMenuConfigPages.push(
        //     {
        //         heading: 'Reclamaciones',
        //         route: '/usuarios/reclamaciones/listado',
        //         keenthemesIcon: 'element-11',
        //         bootstrapIcon: 'bi-shield-exclamation'
        //     });
    }
    if (useUtil().hasPermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_ADMINS"], ROLE_PERMISSION_GRANTS_AVAILABLE['GRANT_READ'])) {
        UsersMenuConfigPages.push(
            {
                heading: 'Administradores',
                route: '/usuarios/administradores/listado',
                keenthemesIcon: 'element-11',
                bootstrapIcon: 'bi-person-vcard'
            });
    }
    if (useUtil().hasPermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_ROLES"], ROLE_PERMISSION_GRANTS_AVAILABLE['GRANT_READ'])) {
        UsersMenuConfigPages.push(
            {
                heading: 'Roles',
                route: '/usuarios/roles/listado',
                keenthemesIcon: 'element-11',
                bootstrapIcon: 'bi-key'
            });
    }

    // if (useUtil().hasPermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_PLANS"], ROLE_PERMISSION_GRANTS_AVAILABLE['GRANT_READ'])) {
    //     UsersMenuConfigPages.push(
    //         {
    //             heading: 'Planes',
    //             route: '/usuarios/planes/listado',
    //             keenthemesIcon: 'element-11',
    //             bootstrapIcon: 'bi-person-vcard'
    //         });
    // }

    MainMenuConfig.push({
        heading: "Gestión Usuarios",
        pages: UsersMenuConfigPages
    });
}

if (useUtil().hasTypePermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_JUDICIAL_PRECEDENT"])
    || useUtil().hasTypePermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_JUDICIAL_PRECEDENT_PRODUCTS"])
    || useUtil().hasTypePermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_JUDICIAL_PRECEDENT_AFFECTED_TYPE"])
    || useUtil().hasTypePermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_JUDGES"])
    || useUtil().hasTypePermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_LAWYERS"])
    || useUtil().hasTypePermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_BANKS"])
    || useUtil().hasTypePermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_AUDIENCES"])
    || useUtil().hasTypePermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_COURTS"])
    || useUtil().hasTypePermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_COURTS_TYPE"])
) {
    if (useUtil().hasTypePermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_JUDICIAL_PRECEDENT"])
        || useUtil().hasTypePermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_JUDICIAL_PRECEDENT_PRODUCTS"])
        || useUtil().hasTypePermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_JUDICIAL_PRECEDENT_AFFECTED_TYPE"])) {

        if (useUtil().hasPermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_JUDICIAL_PRECEDENT"], ROLE_PERMISSION_GRANTS_AVAILABLE['GRANT_READ'])) {
            JudicialSentencesSubMenuConfigPages.push({
                heading: 'Procedimientos',
                route: '/sentencias/procedimientos/listado',
            });
        }
        if (useUtil().hasPermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_JUDICIAL_PRECEDENT_PRODUCTS"], ROLE_PERMISSION_GRANTS_AVAILABLE['GRANT_READ'])) {
            JudicialSentencesSubMenuConfigPages.push({
                heading: 'Productos',
                route: '/sentencias/productos/listado',
            });
        }
        if (useUtil().hasPermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_JUDICIAL_PRECEDENT_AFFECTED_TYPE"], ROLE_PERMISSION_GRANTS_AVAILABLE['GRANT_READ'])) {
            JudicialSentencesSubMenuConfigPages.push({
                heading: 'Afectados',
                route: '/sentencias/afectados/listado',
            });
        }

        JudicialSentencesMenuConfigPages.push(
            {
                sectionTitle: 'Sentencias',
                route: '/sentencias',
                keenthemesIcon: 'element-11',
                bootstrapIcon: 'bi-file-earmark-medical',
                sub: JudicialSentencesSubMenuConfigPages,
            });
    }
    if (useUtil().hasPermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_JUDGES"], ROLE_PERMISSION_GRANTS_AVAILABLE['GRANT_READ'])) {
        JudicialSentencesMenuConfigPages.push(
            {
                heading: 'Jueces',
                route: '/jueces/listado',
                keenthemesIcon: 'element-11',
                bootstrapIcon: 'bi-person-lock'
            });
    }
    if (useUtil().hasPermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_LAWYERS"], ROLE_PERMISSION_GRANTS_AVAILABLE['GRANT_READ'])) {
        JudicialSentencesMenuConfigPages.push(
            {
                heading: 'Abogados',
                route: '/abogados/listado',
                keenthemesIcon: 'element-11',
                bootstrapIcon: 'bi-briefcase'
            });
    }
    if (useUtil().hasPermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_BANKS"], ROLE_PERMISSION_GRANTS_AVAILABLE['GRANT_READ'])) {
        JudicialSentencesMenuConfigPages.push(
            {
                heading: 'Bancos',
                route: '/bancos/listado',
                keenthemesIcon: 'element-11',
                bootstrapIcon: 'bi-bank2'
            });
    }
    if (useUtil().hasPermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_AUDIENCES"], ROLE_PERMISSION_GRANTS_AVAILABLE['GRANT_READ'])) {
        JudicialSentencesMenuConfigPages.push(
            {
                heading: 'Audiencias',
                route: '/audiencias/listado',
                keenthemesIcon: 'element-11',
                bootstrapIcon: 'bi-building'
            });
    }

    if (useUtil().hasTypePermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_COURTS"])
        || useUtil().hasTypePermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_COURTS_TYPE"])) {

        if (useUtil().hasPermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_JUDICIAL_PRECEDENT"], ROLE_PERMISSION_GRANTS_AVAILABLE['GRANT_READ'])) {
            JudicialSentencesCourtSubMenuConfigPages.push({
                heading: 'Listado',
                route: '/juzgados/listado',
            });
        }
        if (useUtil().hasPermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_COURTS_TYPE"], ROLE_PERMISSION_GRANTS_AVAILABLE['GRANT_READ'])) {
            JudicialSentencesCourtSubMenuConfigPages.push({
                heading: 'Tipos',
                route: '/juzgados/tipos/listado',
            });
        }

        JudicialSentencesMenuConfigPages.push(
            {
                sectionTitle: 'Juzgados',
                route: '/juzgados',
                keenthemesIcon: 'element-11',
                bootstrapIcon: 'bi-buildings',
                sub: JudicialSentencesCourtSubMenuConfigPages,
            });
    }

    MainMenuConfig.push({
        heading: "Gestión Sentencias",
        pages: JudicialSentencesMenuConfigPages
    });
}

if (useUtil().hasTypePermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_FAQS"])
    || useUtil().hasTypePermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_PUBLIC_FILES"])
    || useUtil().hasTypePermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_PUBLIC_FILES_TYPE"])
    || useUtil().hasTypePermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_PUBLIC_FILES_PRODUCTS"])
    || useUtil().hasTypePermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_NEWS"])
) {
    if (useUtil().hasPermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_FAQS"], ROLE_PERMISSION_GRANTS_AVAILABLE['GRANT_READ'])) {
        PublicContentMenuConfigPages.push(
            {
                heading: 'FAQS',
                route: '/preguntas-frecuentes/listado',
                keenthemesIcon: 'element-11',
                bootstrapIcon: 'bi-question-circle'
            });
    }

    if (useUtil().hasTypePermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_PUBLIC_FILES"])
        || useUtil().hasTypePermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_PUBLIC_FILES_TYPE"])
        || useUtil().hasTypePermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_PUBLIC_FILES_PRODUCTS"])) {

        if (useUtil().hasPermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_PUBLIC_FILES"], ROLE_PERMISSION_GRANTS_AVAILABLE['GRANT_READ'])) {
            PublicContentFilesSubMenuConfigPages.push({
                heading: 'Listado',
                route: '/archivos/listado',
            });
        }
        if (useUtil().hasPermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_PUBLIC_FILES_TYPE"], ROLE_PERMISSION_GRANTS_AVAILABLE['GRANT_READ'])) {
            PublicContentFilesSubMenuConfigPages.push({
                heading: 'Tipos',
                route: '/archivos/tipos/listado',
            });
        }
        if (useUtil().hasPermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_PUBLIC_FILES_PRODUCTS"], ROLE_PERMISSION_GRANTS_AVAILABLE['GRANT_READ'])) {
            PublicContentFilesSubMenuConfigPages.push({
                heading: 'Productos',
                route: '/archivos/productos/listado',
            });
        }

        PublicContentMenuConfigPages.push(
            {
                sectionTitle: 'Archivos',
                route: '/archivos',
                keenthemesIcon: 'element-11',
                bootstrapIcon: 'bi-folder',
                sub: PublicContentFilesSubMenuConfigPages,
            });
    }

    if (useUtil().hasPermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_NEWS"], ROLE_PERMISSION_GRANTS_AVAILABLE['GRANT_READ'])) {
        PublicContentMenuConfigPages.push(
            {
                heading: 'Novedades',
                route: '/novedades/listado',
                keenthemesIcon: 'element-11',
                bootstrapIcon: 'bi-megaphone'
            });
    }

    MainMenuConfig.push({
        heading: "Contenidos públicos",
        pages: PublicContentMenuConfigPages
    });
}

if (useUtil().hasTypePermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_ANALYTICS"])
    || useUtil().hasTypePermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_ANALYTICS_PLANS"])
    || useUtil().hasTypePermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_ANALYTICS_JUDICIAL_SENTENCES"])
    || useUtil().hasTypePermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_ANALYTICS_FILES"])
) {
    if (useUtil().hasTypePermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_ANALYTICS"])
        || useUtil().hasTypePermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_ANALYTICS_PLANS"])) {

        if (useUtil().hasPermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_ANALYTICS"], ROLE_PERMISSION_GRANTS_AVAILABLE['GRANT_READ'])) {
            AnalyticsSubMenuConfigPages.push({
                heading: 'Datos generales',
                route: '/analiticas/socios/datos-generales',
            });
            AnalyticsSubMenuConfigPages.push({
                heading: 'Resumen mensual',
                route: '/analiticas/socios/resumen-mensual',
            });
            AnalyticsSubMenuConfigPages.push({
                heading: 'General',
                route: '/analiticas/socios/general',
            });
        }
        if (useUtil().hasPermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_ANALYTICS_PLANS"], ROLE_PERMISSION_GRANTS_AVAILABLE['GRANT_READ'])) {
            AnalyticsSubMenuConfigPages.push({
                heading: 'Cambios de plan',
                route: '/analiticas/socios/cambios-de-plan',
            });
        }

        AnalyticsMenuConfigPages.push(
            {
                sectionTitle: 'Socios',
                route: '/analiticas',
                keenthemesIcon: 'element-11',
                bootstrapIcon: 'bi-pie-chart',
                sub: AnalyticsSubMenuConfigPages,
            });
    }

    if (useUtil().hasPermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_ANALYTICS_JUDICIAL_SENTENCES"], ROLE_PERMISSION_GRANTS_AVAILABLE['GRANT_READ'])) {
        AnalyticsMenuConfigPages.push(
            {
                heading: 'Procedimientos',
                route: '/analiticas/procedimientos',
                keenthemesIcon: 'element-11',
                bootstrapIcon: 'bi-bar-chart-line'
            });
    }

    if (useUtil().hasPermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_ANALYTICS_FILES"], ROLE_PERMISSION_GRANTS_AVAILABLE['GRANT_READ'])) {
        AnalyticsMenuConfigPages.push(
            {
                heading: 'Archivos',
                route: '/analiticas/archivos',
                keenthemesIcon: 'element-11',
                bootstrapIcon: 'bi-list-ol'
            });
    }

    MainMenuConfig.push({
        heading: "Analíticas",
        pages: AnalyticsMenuConfigPages
    });
}

if (useUtil().hasTypePermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_FINANCIAL_STRIPE"])) {
    if (useUtil().hasPermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_FINANCIAL_STRIPE"], ROLE_PERMISSION_GRANTS_AVAILABLE['GRANT_READ'])) {
        FinancialMenuConfigPages.push(
            {
                heading: 'Saldos Stripe',
                route: '/financiero/saldos-stripe',
                keenthemesIcon: 'element-11',
                bootstrapIcon: 'bi-stripe'
            });
        FinancialMenuConfigPages.push(
            {
                heading: 'Gestión saldos Stripe',
                route: '/financiero/gestion-saldos-stripe',
                keenthemesIcon: 'element-11',
                bootstrapIcon: 'bi-stripe'
            });
    }

    MainMenuConfig.push({
        heading: "Financiero",
        pages: FinancialMenuConfigPages
    });
}

if (useUtil().hasTypePermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_CONFIGS"])) {
    if (useUtil().hasPermission(currentUser.value.role.permissions, ROLE_PERMISSION_TYPES_AVAILABLE["TYPE_CONFIGS"], ROLE_PERMISSION_GRANTS_AVAILABLE['GRANT_READ'])) {
        ContentSubMenuConfigPages.push({
            heading: 'Compañías',
            route: '/configuraciones/companias/listado',
        });
        ContentSubMenuConfigPages.push({
            heading: 'Junta directiva',
            route: '/configuraciones/junta-directiva/listado',
        });

        ContentMenuConfigPages.push(
            {
                sectionTitle: 'Configuraciones',
                route: '/configuraciones',
                keenthemesIcon: 'element-11',
                bootstrapIcon: 'bi-gear',
                sub: ContentSubMenuConfigPages
            });
    }

    MainMenuConfig.push({
        heading: "Configuración",
        pages: ContentMenuConfigPages
    });
}


export default MainMenuConfig;
